import React from 'react'
import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import styled, {css} from 'styled-components'
import {MainLayout} from 'layouts'
import {useAmplitude} from 'hooks'
import {LinkButton, SEO, AviQuote, Testimonials, DrawstrokeHighlight, CheckCircle} from 'components'
import {
  CreatorLock,
  BlockOne,
  BlockTwo,
  BlockThree,
  ValueOne,
  ValueTwo,
  ValueThree,
  QuestionMark,
  Tilde,
} from 'images/svgs'
import BackgroundSVG from 'images/background-medium.svg'

const BasicSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`

const BasicContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--mobile-width);

  @media only screen and (min-width: 700px) {
    max-width: var(--content-width);
  }
`

const HeroSection = styled(BasicSection)`
  height: 600px;
  background: center / cover no-repeat url(${BackgroundSVG});
  background-color: var(--white);

  @media only screen and (min-width: 700px) {
    min-height: 500px;
    height: 75vh;
  }
`

const HeroContent = styled(BasicContent)`
  text-align: center;

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const HeroFont = styled.h1`
  margin-top: 0;
  @media only screen and (min-width: 700px) {
    font-size: 4rem;
  }
`

const HeroDescription = styled.p`
  margin-bottom: 50px;
  color: var(--dark-grey);

  @media only screen and (min-width: 700px) {
    font-size: 1.25rem;
  }
`

const HintText = styled.p`
  color: var(--dark-grey);
  font-size: 0.9em;
`

const SuggestionSection = styled(BasicContent)`
  padding: 25px 0;
`

const SuggestionContent = styled(BasicContent)`
  display: flex;
  flex-direction: column;

  & > a {
    align-self: flex-end;
  }
`

const SectionTitleDiv = styled.div`
  margin: 25px 0;
  text-align: center;

  h2 {
    margin: 0;
  }

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const EyebrowText = styled.p`
  color: var(--blue);

  font-weight: bold;
`

const SubtitleText = styled.p`
  color: var(--dark-grey);
`

const ProjectsBox = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-bottom: 20px;

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const CardStyle = styled(Link)`
  display: flex;
  align-items: center;

  border-radius: 5px;
  padding: 15px;
  width: 100%;

  background-color: var(--white);

  font-size: 0.9em;
  transition: var(--transition);

  @media only screen and (min-width: 700px) {
    &:hover {
      filter: var(--shadow);
    }
  }
`

const ProjectIcon = styled(Img)`
  margin: 15px;
  margin-left: 0;

  width: 80px;
  flex-shrink: 0;
`

const ProjectInfo = styled.div`
  text-align: left;

  h5 {
    margin: 0 0 10px;
    transition: var(--transition);

    @media only screen and (min-width: 700px) {
      ${CardStyle}:hover & {
        color: var(--blue);
      }
    }
  }
`

const MetaSection = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  font-size: 0.9em;
  color: var(--grey);
`

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px 10px;
  margin-right: 10px;
  border-radius: 5px;

  font-weight: 600;
  background-color: var(--violet);
  color: var(--black);
`

const StyledLock = styled(CreatorLock)`
  height: 25px;
  margin-right: 16px;
`

const ValueSection = styled(BasicSection)`
  background-color: var(--white);

  padding: 50px 0;
  clip-path: polygon(0 0, 100% 50px, 100% 100%, 0% 100%);

  @media only screen and (min-width: 700px) {
    padding-top: 100px;
    clip-path: polygon(0 0, 100% 100px, 100% 100%, 0% 100%);
  }

  @media only screen and (min-width: 1200px) {
    padding-top: 150px;
    clip-path: polygon(0 0, 100% 150px, 100% 100%, 0% 100%);
  }
`

const ValueContent = styled(BasicContent)``

const ValueProp = styled.div`
  @media only screen and (min-width: 700px) {
    display: flex;
    flex-direction: ${({reversed}) => (reversed ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;

    margin: 25px 0;
  }

  @media only screen and (min-width: 1200px) {
    margin: 50px 0;
  }
`

const ValueDescription = styled.div`
  @media only screen and (min-width: 700px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    flex-shrink: 0;
    width: 400px;

    margin: ${({reversed}) => (reversed ? `0 5vw 0 0` : `0 0 0 5vw`)};

    p {
      margin-top: 0;
    }
  }

  @media only screen and (min-width: 1200px) {
    margin: ${({reversed}) => (reversed ? `0 10vw 0 0` : `0 0 0 10vw`)};
  }
`

const valueImage = css`
  width: 100%;

  @media only screen and (min-width: 700px) {
    height: 100%;
  }
`

const ValueImage1 = styled(ValueOne)`
  ${valueImage}
`

const ValueImage2 = styled(ValueTwo)`
  ${valueImage}
`

const ValueImage3 = styled(ValueThree)`
  ${valueImage}
`

const FAQAndTopicSection = styled(BasicSection)`
  padding: 50px 0;
`

const FAQAndTopicContent = styled(BasicContent)`
  & > a {
    align-self: flex-end;
    margin: 25px 0;
  }

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const FAQBox = styled.div`
  display: grid;
  grid-gap: 20px;

  margin-bottom: 25px;

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    flex: 1;
    margin-right: 1em;
  }

  p {
    flex: 8;
  }
`

const TopicBox = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  width: 100%;
  margin-top: 25px;

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const TopicLink = styled(Link)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5em;
    flex-shrink: 0;
  }

  @media only screen and (min-width: 700px) {
    &:hover {
      text-decoration: underline;
    }
  }
`

export const HomePage = ({data}) => {
  const {logEvent} = useAmplitude()
  const projects = data.allStrapiProject.nodes
  const topics = data.allStrapiTopic.nodes

  const logClick = (buttonName, data = {}) => logEvent('home-page-button-click', {buttonName, ...data})

  return (
    <MainLayout>
      <SEO title='' />
      <HeroSection>
        <HeroContent>
          <HeroFont>
            Learn Python
            <br />
            Through <DrawstrokeHighlight type='circle'>Projects</DrawstrokeHighlight>
          </HeroFont>
          <HeroDescription>
            You{`'`}ve taken a lengthy and boring coding class, but you{`'`}re clueless about applying these concepts to
            build your ideas. The Codex shows you how to <strong>build fun, practical projects</strong>. Now, you can
            stand out from the crowd and <strong>land your dream job</strong>.{' '}
          </HeroDescription>
          <LinkButton to='/app/get-started' tier='primary' onClick={() => logClick('get-started')}>
            Try our projects for free
          </LinkButton>
          <HintText>Join the 7.1K makers and builders using The Codex.</HintText>
        </HeroContent>
      </HeroSection>
      <SuggestionSection>
        <SuggestionContent>
          <SectionTitleDiv>
            <h2>
              If you{`'`}ve ever said, {`"`}I wish I knew how to build this...{`"`}{' '}
            </h2>
            <SubtitleText>Build creative projects the easy way using The Codex.</SubtitleText>
          </SectionTitleDiv>
          <ProjectsBox>
            {projects.map((project) => (
              <CardStyle
                key={project.slug}
                to={`/projects/${project.slug}`}
                onClick={() => logClick('project-preview', {slug: project.slug})}
              >
                <ProjectIcon fluid={project.gatsbyIcon.childImageSharp.fluid} alt={`${project.title} icon`} />
                <ProjectInfo>
                  <h5>{project.title}</h5>
                  <MetaSection>
                    {!project.free && <StyledLock />}
                    {project.categories && project.categories.length !== 0 && <Tag>{project.categories[0].tag}</Tag>}
                  </MetaSection>
                </ProjectInfo>
              </CardStyle>
            ))}
          </ProjectsBox>
          <LinkButton to='/projects' tier='tertiary' onClick={() => logClick('all-projects')}>
            All projects {`->`}
          </LinkButton>
        </SuggestionContent>
      </SuggestionSection>
      <ValueSection>
        <ValueContent>
          <SectionTitleDiv>
            <EyebrowText>WHY PROJECTS ARE 10x BETTER</EyebrowText>
            <h2>You never have to feel stuck, alone, or stagnant in building again.</h2>
            <SubtitleText>It’s like having a direct line to your very own programming coach!</SubtitleText>
          </SectionTitleDiv>
          <ValueProp>
            <ValueImage1 />
            <ValueDescription>
              <h3>
                <BlockOne style={{margin: `0 0.5em -0.3em 0`}} />
                Faster Than Courses
              </h3>
              <p>
                Webcasted lectures get mundane and courses are muddled in theory. Stop listening to hours of Youtube
                videos with instructors sounding like robots.
              </p>
              <p>Projects allow you learn and apply quickly.</p>
            </ValueDescription>
          </ValueProp>
          <ValueProp reversed>
            <ValueImage2 />
            <ValueDescription reversed>
              <h3>
                <BlockTwo style={{margin: `0 0.5em -0.3em 0`}} />
                Hands On Learning
              </h3>
              <p>Practical. Actionable. Clear. Complete. Our projects help you become a doer.</p>
              <p>If you want to create tangible products and learn while building, The Codex is how.</p>
            </ValueDescription>
          </ValueProp>
          <ValueProp>
            <ValueImage3 />
            <ValueDescription>
              <h3>
                <BlockThree style={{margin: `0 0.5em -0.3em 0`}} />
                Showcase Your Skills
              </h3>
              <p>The real “secret” to getting a job is showing that you know the skills you say you do.</p>
              <p>
                The Codex gives you the superpower of building, so you can show off to companies, friends, and family
                that you are a real developer.
              </p>
            </ValueDescription>
          </ValueProp>
        </ValueContent>
      </ValueSection>
      <FAQAndTopicSection>
        <FAQAndTopicContent>
          <SectionTitleDiv>
            <h2>The Codex is right for you if you can say “yes” to any of these questions</h2>
          </SectionTitleDiv>
          <FAQBox>
            <Question>
              <QuestionMark />
              <p>
                Have you already taken a Python course and are now left wondering how you can apply these skills to
                something tangible?
              </p>
            </Question>
            <Question>
              <QuestionMark />
              <p>
                Are you looking to stand out from your peers in the job application process and showcase your skills to
                future employers?
              </p>
            </Question>
            <Question>
              <QuestionMark />
              <p>
                Do you want to be able to build Python projects but they seem daunting and you have no idea where to
                start?
              </p>
            </Question>
            <Question>
              <QuestionMark />
              <p>
                Have you always been curious on how to bring your ideas to life, but never figured out how to achieve
                that?
              </p>
            </Question>
          </FAQBox>
          <SectionTitleDiv>
            <h2>
              <Tilde style={{marginRight: `0.5em`}} />
              Get the “golden nuggets” on topics like:
              <Tilde style={{marginLeft: `0.5em`}} />
            </h2>
          </SectionTitleDiv>
          <TopicBox>
            {topics.map((topic) => (
              <TopicLink
                key={topic.slug}
                to={`/topics/${topic.slug}`}
                onClick={() => logClick('topic-preview', {slug: topic.slug})}
              >
                <CheckCircle checked />
                {topic.title}
              </TopicLink>
            ))}
          </TopicBox>
          <LinkButton to='/topics' tier='tertiary' onClick={() => logClick('all-topics')}>
            All topics {`->`}
          </LinkButton>
        </FAQAndTopicContent>
      </FAQAndTopicSection>
      <Testimonials />
      <AviQuote />
    </MainLayout>
  )
}

export default HomePage
export const HomePageData = graphql`
  query HomePageData {
    allStrapiProject(sort: {fields: published_at, order: DESC}, limit: 6) {
      nodes {
        slug
        title
        free
        categories {
          tag
        }
        gatsbyIcon {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiTopic(sort: {fields: published_at, order: DESC}, limit: 9) {
      nodes {
        slug
        title
      }
    }
  }
`
